import React from 'react'
import Layout from '../containers/layout'
import SubPage from '../containers/subpage'

const NotFoundPage = () => (
    <Layout>
        <SubPage back={'/'}>
            <h1>Seite Existiert nicht.</h1>
            <p>Leider existiert die aufzurufende Seite nicht.</p>
        </SubPage>
    </Layout>
);

export default NotFoundPage
